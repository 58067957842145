<template>
  <CheckboxWithValidation
    :value="value"
    :false-value="falseValue"
    :true-value="trueValue"
    :name="name"
    :disabled="disabled"
    :rules="rules"
    :errors="errors"
    :field-classes="$attrs.class"
    @check="handleCheck"
  >
    Ho preso visione dell'<a
      href="#"
      class="cursor-pointer text-blue underline outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:rounded focus-visible:ring-blue"
      @keydown.enter.stop="handleViewPrivacy"
      @click.prevent="handleViewPrivacy"
      >Informativa Privacy</a
    >
    sul trattamento dei dati personali.*
  </CheckboxWithValidation>
</template>

<script>
import CheckboxWithValidation from '~/components/CheckboxWithValidation.vue'
import ModalInner from '~/components/ModalInner'
import PrivacyPolicy from '~/components/PrivacyPolicy'

export default {
  components: {
    CheckboxWithValidation,
  },
  props: {
    value: {
      type: null,
      default: null,
    },
    name: {
      type: String,
      default: 'Privacy',
    },
    rules: {
      type: [String, Object],
      default: () => {
        return { required: { allowFalse: false } }
      },
    },
    falseValue: {
      type: [Boolean, String, Number],
      default: false,
    },
    trueValue: {
      type: [Boolean, String, Number],
      default: true,
    },
    errors: {
      type: Object,
      default: () => null,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    policy: {
      type: String,
      default: 'lead',
    },
    modalWidth: {
      type: String,
      default: '83rem',
    },
  },
  methods: {
    handleViewPrivacy() {
      this.$oruga.modal.open({
        parent: this,
        width: this.modalWidth,
        animation: 'ucrs-modal-',
        component: ModalInner,
        props: {
          component: PrivacyPolicy,
          componentProps: { policy: this.policy },
        },
      })
    },
    handleCheck(val) {
      this.$emit('input', val)
    },
  },
}
</script>
