<template>
  <ValidationProvider
    ref="provider"
    :vid="vid"
    :name="$attrs.name"
    :slim="true"
    :rules="rules"
    mode="aggressive"
  >
    <OField
      :variant="errors && errors[$attrs.name] ? 'danger-noBox' : ''"
      :message="errors && errors[$attrs.name] && errors[$attrs.name][0]"
      :root-class="fieldClasses"
      :data-name="$attrs.name"
      tabindex="-1"
    >
      <OCheckbox
        ref="Checkbox"
        v-model="innerValue"
        :name="$attrs.name"
        :disabled="$attrs.disabled"
        :class="classes"
        :false-value="falseValue"
        :true-value="trueValue"
        :root-class="rootClass"
        tabindex="0"
        @input="$emit('input', $event)"
      >
        <slot></slot>
      </OCheckbox>
    </OField>
  </ValidationProvider>
</template>

<script>
export default {
  inheritAttrs: false,

  props: {
    vid: {
      type: String,
      default: '',
    },

    fieldClasses: {
      type: String,
      default: '',
    },

    classes: {
      type: String,
      default: '',
    },

    value: {
      type: null,
      default: null,
    },

    label: {
      type: String,
      default: 'Label',
    },

    falseValue: {
      type: [Boolean, String, Number],
      default: false,
    },

    trueValue: {
      type: [Boolean, String, Number],
      default: true,
    },

    rules: {
      type: [Object, String],
      default: '',
    },

    errors: {
      type: Object,
      default: () => {
        return null
      },
    },
  },

  data: () => ({
    innerValue: '',
  }),

  computed: {
    rootClass() {
      const rootClass =
        this.errors &&
        this.errors[this.$attrs.name] &&
        this.errors[this.$attrs.name][0]
          ? 'ucrs-checkbox--danger'
          : ''
      return rootClass
    },
  },

  watch: {
    innerValue(newVal) {
      this.$emit('check', newVal)
    },

    value(newVal) {
      this.innerValue = newVal
    },
  },

  created() {
    if (this.value) {
      this.innerValue = this.value
    }
  },

  mounted() {
    const checkboxRef = this.$refs.Checkbox
    const inputRef = checkboxRef.$refs.input
    const labelRef = checkboxRef.$refs.label

    inputRef.setAttribute('tabindex', -1)
    labelRef.setAttribute('tabindex', 0)

    function focusToggle() {
      labelRef.focus()
    }

    inputRef.addEventListener('focus', focusToggle)
  },
}
</script>

<style lang="postcss">
@import '~/assets/css/components/checkbox';
@import '~/assets/css/components/field';
</style>
