<template>
  <div class="py-18">
    <h1 class="section-headline text-center mb-18">
      Informativa sul trattamento e la protezione dei dati personali
    </h1>
    <ol class="list-decimal ml-6">
      <li class="mb-12 marker:font-bold">
        <p class="paragraph mb-6">
          Titolare del trattamento e responsabile della protezione dei dati
        </p>
        <p class="mb-6">
          Il <strong>Titolare del Trattamento</strong> è
          <strong>UniCredit RE Services S.p.A.</strong> con sede legale presso
          {{ $config.brandAddress.full }} (UniCredit RE Services).
        </p>
        <p class="mb-6">
          Il <strong>Responsabile della protezione dei dati</strong> può essere
          contattato presso <strong>UniCredit S.p.A.</strong>, Data Protection
          Office, Piazza Gae Aulenti n.1, Tower B, 20154 Milano, E-mail:
          <a
            class="text-blue underline hover:no-underline"
            target="_blank"
            href="mailto:Group.DPO@unicredit.eu"
            >Group.DPO@unicredit.eu</a
          >, PEC:
          <a
            class="text-blue underline hover:no-underline"
            target="_blank"
            href="mailto:Group.DPO@pec.unicredit.eu"
            >Group.DPO@pec.unicredit.eu</a
          >.
        </p>
      </li>
      <li class="mb-12 marker:font-bold">
        <p class="paragraph mb-6">Finalità e base giuridica del trattamento</p>
        <template v-if="policy === 'registration'">
          <p class="mb-6">
            UniCredit RE Services tratta i Suoi dati personali al fine
            <strong>
              di riscontrare alla Sua richiesta di registrazione all'area
              riservata presente all'interno del sito
            </strong>
            <a
              class="text-blue underline hover:no-underline"
              target="_blank"
              href="http://www.unicreditres.it"
              >www.unicreditres.it</a
            >. La base giuridica che legittima i conseguenti trattamenti è
            l'esecuzione di un contratto di cui l'interessato è parte o
            l'esecuzione di misure precontrattuali adottate su richiesta dello
            stesso di cui all'art. 6, comma 1, lettera b) del Regolamento UE
            2016/679 (GDPR). Il conferimento dei dati è necessario per
            riscontrare alla Sua richiesta; in mancanza di essi, UniCredit RE
            Services potrebbe essere nell'impossibilità di dare seguito alla
            stessa.
          </p>
        </template>
        <template v-else>
          <p class="mb-6">
            UniCredit RE Services tratta i Suoi dati personali per le seguenti
            finalità:
          </p>
          <ol class="list-[upper-alpha] ml-16">
            <li class="font-bold">
              <strong
                >necessità di riscontrare alla Sua richiesta di
                contatto.</strong
              >
              <span class="font-normal">
                La base giuridica che legittima i conseguenti trattamenti è
                l'esecuzione di un contratto di cui l'interessato è parte o
                l'esecuzione di misure precontrattuali adottate su richiesta
                dello stesso di cui all'art. 6, comma 1, lettera b) del
                Regolamento UE 2016/679 (GDPR). Il conferimento dei dati è
                necessario per riscontrare alla Sua richiesta. In mancanza di
                essi, UniCredit RE Services S.p.A. potrebbe essere
                nell'impossibilità di gestire e dare seguito alla stessa.
              </span>
            </li>
            <li class="font-bold">
              necessità di riscontrare alla Sua richiesta di registrazione
              all'area riservata presente all'interno del sito
              <span class="font-normal">
                <a
                  class="text-blue underline hover:no-underline"
                  href="http://www.unicreditres.it"
                  target="_blank"
                >
                  www.unicreditres.it</a
                >. La base giuridica che legittima i conseguenti trattamenti è
                l'esecuzione di un contratto di cui l'interessato è parte o
                l'esecuzione di misure precontrattuali adottate su richiesta
                dello stesso di cui all'art. 6, comma 1, lettera b) del
                Regolamento UE 2016/679 (GDPR). Il conferimento dei dati è
                necessario per riscontrare alla Sua richiesta; in mancanza di
                essi, UniCredit RE Services potrebbe essere nell'impossibilità
                di dare seguito alla stessa.
              </span>
            </li>
            <li class="font-bold">
              necessità di riscontrare alla Sua richiesta relativa
              all'attivazione del servizio facoltativo di segnalazione degli
              immobili aventi caratteristiche similari a quelli da Lei
              ricercati.
              <span class="font-normal">
                La base giuridica che legittima i conseguenti trattamenti è il
                Suo consenso di cui all'art. 6, comma 1, lettera a) del GDPR,
                che Lei è libero di prestare o meno e che può, comunque,
                revocare in qualsiasi momento. Il consenso sarà ritenuto da Lei
                prestato selezionando il tick box "Registrati all'area
                riservata". Il conferimento dei dati necessari a tali fini non è
                obbligatorio e il rifiuto di fornirli non determina alcuna
                conseguenza negativa, salvo l'impossibilità di ricevere le
                segnalazioni sopra descritte.
              </span>
            </li>
          </ol>
        </template>
      </li>
      <li class="mb-12 marker:font-bold">
        <p class="paragraph mb-6">Categorie di dati trattati</p>
        <p class="mb-6">
          UniCredit RE Services tratta dati personali raccolti direttamente
          presso di Lei, ovvero presso terzi, che includono, a titolo
          esemplificativo,
          <strong>dati anagrafici</strong> (es. nome, cognome, indirizzo, data e
          luogo di nascita),
          <strong>
            informazioni per la gestione dei servizi immobiliari e degli
            incarichi di mediazione immobiliare
          </strong>
          (es. estremi conto corrente bancario per pagamento provvigioni),
          <strong>dati relativi all'immagine</strong> (es. foto su carta
          d'identità) e altri dati riconducibili alle categorie sopra indicate.
        </p>
      </li>
      <li class="mb-12 marker:font-bold">
        <p class="paragraph mb-6">
          Destinatari o categorie di destinatari dei dati personali
        </p>
        <p class="mb-6">
          Possono venire a conoscenza dei Suoi dati in qualità di
          <strong> Responsabili del trattamento </strong> e/o
          <strong>Titolari autonomi del trattamento</strong> le persone fisiche
          e giuridiche di cui all'elenco consultabile nella sezione "Privacy"
          del sito
          <a
            class="text-blue underline hover:no-underline"
            target="_blank"
            href="http://www.unicreditres.it"
            >www.unicreditres.it</a
          >. Possono, altresì, venire a conoscenza dei Suoi dati, in qualità di
          <strong> persone autorizzate al trattamento dei dati personali</strong
          >, relativamente ai dati necessari allo svolgimento delle mansioni
          assegnategli, le persone fisiche appartenenti alle seguenti categorie:
          i lavoratori dipendenti di UniCredit RE Services o presso di essa
          distaccati, i lavoratori interinali, gli stagisti, i consulenti e i
          dipendenti delle società esterne nominate Responsabili.
        </p>
        <p class="mb-6">I dati possono essere comunicati:</p>
        <ol class="list-[upper-roman] ml-16">
          <li class="marker:font-normal">
            a quei soggetti cui tale comunicazione debba essere effettuata in
            adempimento di un obbligo previsto dalla legge, da un regolamento o
            dalla normativa comunitaria. Maggiori informazioni al riguardo sono
            rinvenibili nell'informativa per i clienti pubblicata nella sezione
            "Privacy" del sito web
            <a
              class="text-blue underline hover:no-underline"
              target="_blank"
              href="http://www.unicreditres.it"
              >www.unicreditres.it</a
            >;
          </li>
          <li class="marker:font-normal">
            agli intermediari finanziari appartenenti al Gruppo UniCredit, in
            base a quanto disposto dalla normativa antiriciclaggio (cfr.
            <strong> Art. 39, comma 3, del D. Lgs. n. 90/2017 </strong>), che
            prevede la possibilità di procedere alla comunicazione dei dati
            personali relativi alle segnalazioni considerate sospette tra gli
            intermediari finanziari facenti parte del medesimo Gruppo UniCredit;
          </li>
          <li class="marker:font-normal">
            alle società appartenenti al Gruppo UniCredit, ovvero controllate o
            collegate ai sensi dell'art. 2359 C.C. (situate anche all'estero),
            quando tale comunicazione sia consentita in conseguenza di un
            provvedimento del Garante della Privacy o di una previsione di
            legge.
          </li>
        </ol>

        <p class="mb-6">
          L'elenco dettagliato dei soggetti ai quali i dati possono essere
          comunicati può essere consultato nella sezione "Privacy" del sito web
          <a
            class="text-blue underline hover:no-underline"
            target="_blank"
            href="http://www.unicreditres.it"
            >www.unicreditres.it</a
          >.
        </p>
      </li>
      <li class="mb-12 marker:font-bold">
        <p class="paragraph mb-6">Trasferimento dei dati verso paesi terzi</p>
        <p class="mb-6">
          UniCredit RE Services informa che i dati personali potranno essere
          trasferiti anche in paesi non appartenenti all'Unione Europea o allo
          Spazio Economico Europeo (cd. Paesi Terzi) riconosciuti dalla
          Commissione Europea aventi un livello adeguato di protezione dei dati
          personali o, in caso contrario, solo se sia garantito contrattualmente
          da tutti i fornitori di UniCredit situati nel Paese Terzo un livello
          di protezione dei dati personali adeguato rispetto a quello
          dell'Unione Europea (es. tramite la sottoscrizione delle clausole
          contrattuali standard previsti dalla Commissione Europea) e che sia
          sempre assicurato l'esercizio dei diritti degli Interessati. Ulteriori
          informazioni possono essere richieste scrivendo a
          <a
            class="text-blue underline hover:no-underline"
            target="_blank"
            href="mailto:Group.DPO@unicredit.eu"
            >Group.DPO@unicredit.eu</a
          >.
        </p>
      </li>
      <li class="mb-12 marker:font-bold">
        <p class="paragraph mb-6">Diritti degli interessati</p>
        <p class="mb-6">
          Il GDPR attribuisce agli interessati (persone fisiche, ditte
          individuali e/o liberi professionisti) specifici diritti, tra i quali
          quello di conoscere quali sono i dati personali in possesso di
          UniCredit RE Services e come questi vengono utilizzati (<strong
            >Diritto di accesso</strong
          >), di ottenerne l'<strong>aggiornamento</strong>, la
          <strong>rettifica</strong> o, se vi è interesse,
          l'<strong>integrazione</strong>, nonché la
          <strong>cancellazione</strong>, la trasformazione in
          <strong>forma anonima</strong> o la <strong>limitazione</strong>.
        </p>
        <template v-if="policy === 'lead'">
          <p class="mb-6">
            Gli Interessati e le persone giuridiche, gli enti e le associazioni
            possono in qualsiasi momento revocare, laddove rilasciato, il
            consenso al trattamento dei dati personali per
            <strong
              >l'attivazione del servizio facoltativo mediante l'eliminazione
              del proprio account dell'area riservata</strong
            >.
          </p>
          <p class="mb-6">
            UniCredit RE Services pone in evidenza che la revoca avrà effetto
            solo per il futuro.
          </p>
        </template>
      </li>
      <li class="mb-12 marker:font-bold">
        <p class="paragraph mb-6">
          Periodo di conservazione dei dati e diritto alla cancellazione (i.e.
          diritto all'oblio)
        </p>
        <p class="mb-6">
          UniCredit RE Services tratta e conserva i Suoi dati personali per
          tutta la durata del rapporto contrattuale, per l'esecuzione degli
          adempimenti allo stesso inerenti e conseguenti, per il rispetto degli
          obblighi di legge e regolamentari applicabili, nonché per finalità
          difensive proprie o di terzi e fino alla scadenza del periodo di
          prescrizione di legge applicabile, decorrente dalla data di chiusura
          dei rapporti.
        </p>
        <p class="mb-6">
          <strong>Al termine del periodo di conservazione applicabile</strong>,
          i dati personali riferibili agli <strong>Interessati</strong> saranno
          <strong>cancellati</strong> o
          <strong>
            conservati in una forma che non consenta l'identificazione
          </strong>
          dell'Interessato (es. anonimizzazione irreversibile), a meno che il
          loro ulteriore trattamento sia necessario per uno o più dei seguenti
          scopi: I) risoluzione di
          <strong>precontenziosi e/o contenziosi</strong> avviati prima della
          scadenza del periodo di conservazione; II) per dare seguito ad
          <strong>indagini/ispezioni</strong> da parte di funzioni di controllo
          interno e/o autorità esterne avviati prima della scadenza del periodo
          di conservazione; III) per dare seguito a richieste della
          <strong> pubblica autorità italiana e/o estera </strong>
          pervenute/notificate a UniCredit RE Services prima della scadenza del
          periodo di conservazione.
        </p>
      </li>
      <li class="mb-12 marker:font-bold">
        <p class="paragraph mb-6">Diritto alla portabilità</p>
        <p class="mb-6">
          Ciascun Interessato può chiedere di ricevere o chiedere il
          trasferimento dei dati personali a lui riferibili in possesso di
          UniCredit RE Services S.p.A. in un
          <strong>formato strutturato, di uso comune e leggibile</strong> per
          ulteriori usi personali ovvero per fornirli ad altro titolare del
          trattamento (<strong>Diritto alla portabilità</strong>).
        </p>
        <p class="mb-6">
          In particolare, i dati che possono essere oggetto di portabilità sono
          i
          <strong>dati anagrafici</strong> (es. nome, cognome, titolo, data
          nascita, sesso, luogo nascita, residenza, ecc.), nonché
          <strong>
            un set di dati generati dall'attività di mediazione immobiliare
          </strong>
          che UniCredit RE Services S.p.A. ha definito per ciascuna
          <strong>macrocategoria di prodotti/servizi</strong> (es. rapporti in
          essere o estinti, movimentazione).
        </p>
      </li>
      <li class="mb-12 marker:font-bold">
        <p class="paragraph mb-6">Modalità di esercizio dei diritti</p>
        <p class="mb-6">
          Ciascun Interessato per esercitare i diritti di cui ai paragrafi 6, 7
          e 8 potrà rivolgersi a: UniCredit RE Services S.p.A., Legal &
          Compliance,
          {{ $config.brandAddress.full }}, fax +39 0297688500, indirizzo e-mail:
          <a
            class="text-blue underline hover:no-underline"
            target="_blank"
            href="mailto:privacy@unicreditsubitocasa.it"
            >privacy@unicreditsubitocasa.it</a
          >.
        </p>
        <p class="mb-6">
          Il termine per la risposta è <strong>un (1) mese</strong>, prorogabile
          di <strong>due (2) mesi</strong> in casi di particolare complessità;
          in questi casi, UniCredit RE Services fornisce almeno una
          comunicazione interlocutoria entro <strong>un (1) mese</strong>.
        </p>
        <p class="mb-6">
          L'esercizio dei diritti è, in linea di principio, gratuito; UniCredit
          RE Services si riserva il diritto di chiedere un contributo in caso di
          richieste manifestamente infondate o eccessive (anche ripetitive).
        </p>
        <p class="mb-6">
          UniCredit RE Services ha il diritto di chiedere informazioni
          necessarie a fini identificativi del richiedente.
        </p>
      </li>
      <div>
        <p class="paragraph mb-6">
          Reclamo o segnalazione al garante per la protezione dei dati personali
        </p>
        <p class="mb-6">
          UniCredit RE Services La informa che Lei ha diritto di proporre
          reclamo ovvero effettuare una segnalazione al Garante per la
          Protezione dei Dati Personali oppure in alternativa presentare ricorso
          all'Autorità Giudiziaria. I contatti del Garante per la Protezione dei
          Dati Personali sono consultabili sul sito web
          <a
            class="text-blue underline hover:no-underline"
            target="_blank"
            href="http://www.garanteprivacy.it"
            >http://www.garanteprivacy.it</a
          >.
        </p>
      </div>
    </ol>
  </div>
</template>

<script>
export default {
  props: {
    policy: {
      type: String,
      default: 'lead',
    },
  },
}
</script>
